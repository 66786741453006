.pricing-card {
  cursor: pointer;
  box-sizing: border-box;
  width: 324px;
  background-color: rgb(var(--rgbSurface)) !important;
  margin-bottom: 1rem;
  border-radius: 0.75rem !important;
}

.disabled-card {
  opacity: 0.3;
}

.pricing-card:not(:last-child) {
  margin-right: 1rem;
}

.drop-img {
  width: auto;
}
