.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: var(--space3XL) var(--spaceXL);
  z-index: 16;
  position: relative;
  font-size: var(--fontSizeBodyS);
  color: var(--colorTextLight);
  background-color: rgb(10, 10, 10);
}

.footer__link {
  display: inline-flex;
}

.footer__date {
  padding-right: var(--spaceXS);
  display: inline-flex;
}

.helium-icon {
  width: 50px;
  height: 50px;
  opacity: 0.7;
}

h3.palm-label {
  margin-left: 0.5rem;
  color: white;
  font-size: 24px;
}

.left-container, .middle-container, .right-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-self: stretch;
}

.left-content {
  display: flex;
  align-items: center;
}

.social-icon {
  fill: white;
  color: white;
  height: 36px;
  width: 36px;
  opacity: 0.7;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-right: 0.5rem;
}

h1.footer-h1 {
  font-size: 28px;
  color: white;
  opacity: 1.0;
  margin-bottom: 1rem;
}

h2.footer-h2 {
  font-size: 14px;
  color: white;
  opacity: 0.7;
  font-weight: 500;
  margin: 0.5rem 0;
}